






















































































































import AuthService from "@/services/AuthService";
import { Component, Vue } from "vue-property-decorator";
import router from "../router/index";

@Component
export default class Login extends Vue {
  private username: string = "";
  private password1: string = "";
  private password2: string = "";
  private hasError: boolean = false;
  private error: string = "";
  private token: string = router.currentRoute.params.token || "";

  request() {
    AuthService.reset({ username: this.username, email: this.username }).then(
      (response) => router.push({ name: "home" })
    );
  }

  reset() {
    AuthService.newpassword(this.token, this.password1).then((response) => {
      if (response.status === 204) {
        this.error = "error";
      } else {
        router.push({ name: "home" });
      }
    });
  }
}
